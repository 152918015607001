<template>
    <div style="background: linear-gradient(#5d92c0, white);" v-bind:style="'height:'+  (screenHeight) +'px;'">
        <div class="w3-row">
            <div class="w3-col l4" style="opacity: 0;">
                spasi
            </div>
            <div class="w3-col l4 m12 s12">
                <div style="background-color:white;margin:60px 10px 10px 10px ;padding-bottom:10px;" class="w3-round-large" >
                    <div style="padding:20px 0 0 20px;" class="w3-row">
                        <div class="w3-col s4">
                            <img
                                src="assets/images/logo_person.jpg"
                                width="100"
                                height="120"
                            />
                        </div>
                        <div class="w3-col s8">
                            <h3 style="font-weight:bold;margin:30px 0 0 0;">LOGIN ADMIN</h3>
                        </div>
                    </div>
                    <div id="panah_atas"></div>
                    <div class="w3-theme-d2 w3-round-large" style="background-image: url('assets/images/bg.png');margin:0 10px;color:white;padding:30px;">
                        <h3 style="font-style:italic;color:yellow;margin:0">WELCOME BACK</h3>
                        <h3 style="margin:0;">Log In to BRANTAS TUNTAS</h3>
                        <form class="" v-on:submit.prevent="setLogin" autocomplete="off">
                            <div class="w3-row w3-section">
                                <div class="w3-col" style="width:50px">
                                    <i class="w3-xxlarge fa fa-user"></i>
                                </div>
                                <div class="w3-rest">
                                    <input class="w3-input w3-border w3-round-large" v-model="rec.username" id="username" placeholder="User Name" type="text" ref="email">
                                </div>
                            </div>
                            <div class="w3-row w3-section">
                                <div class="w3-col" style="width:50px">
                                    <i class="w3-xxlarge fa fa-lock"></i>
                                </div>
                                <div class="w3-rest" style="position: relative;">
                                    <input class="w3-input w3-border w3-round-large" v-model="rec.pass" placeholder="Password" :type="lihat?'text':'password'">
                                    <button v-on:click="lihat=!lihat" type="button" class="w3-btn w3-text-black" style="position: absolute;top: 0;right: 0;"><i class="fa" :class="lihat?'fa-eye':'fa-eye-slash'"></i></button>
                                </div>
                            </div>
                            <div class="w3-row w3-section w3-white w3-border w3-round-large">
                                <div class="w3-col l5 m3 s7">
                                    <div style="cursor: pointer;" v-on:click="loadCapcha()" v-html="cap.image_url"></div>
                                </div>
                                <div
                                    class="w3-col l7 m9 s5"
                                    style="padding:5px 10px 0 0; position: relative"
                                >
                                    <i style="position: absolute;left: -20px;top: 13px;">&#61;</i>
                                    <input class="w3-input w3-border-0" name="capcha" v-model="rec.cap" type="text" placeholder="Captcha">
                                </div>
                            </div>
                            <div class="w3-row">
                                <div class="w3-col l6">
                                    <button type="submit" class="w3-btn w3-white w3-theme-l5 w3-row w3-round" >
                                        <span class="w3-col s7" style="margin-right:15px;font-weight:bold;">LOGIN</span>
                                        <i class="w3-xlarge fa fa-sign-in w3-col s2"></i>
                                    </button>
                                </div>
                                <div class="w3-col l6" style="text-align:right;">
                                    <div class="w3-row">
                                        <div class="w3-col l10">
                                            <input class="w3-check w3-round" type="checkbox" v-model="rec.ingat" checked="checked">
                                        </div>
                                        <div class="w3-col l12" style="padding-top:5px;">
                                            <span>Remember Me</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="w3-col l4" style="opacity: 0;">
                spasi
            </div>
        </div>
    </div>
</template>
<script lang="js">
/* eslint-disable */
import md5 from  '../../assets/doevent/md5.js';
import config from "@/conf/Config.js";
import axios from "axios";
axios.defaults.baseURL = config.apiUrl;
axios.defaults.headers.common["authorization"] = localStorage.dretailtoken;


export default {
    head: {
        title: {
            inner: "Login",
        },
    },
    name: "Login",
    data() {
        return {
            lihat : false,
            screenHeight: 0,
            cap: {
                image: "",
                image_url: "",
            },
            rec: {
                username: "",
                pass: "",
                password: "",
                cap: "",
                ingat: "T",
            },
            recscounter: [],
            filters: {
                operator: "AND",
                field: [
                    {
                        key: "username",
                        type: "text",
                        name: "username",
                        filter: true,
                        width: "30px",
                        grid: true,
                    },
                ],
                find: [],
            },
        };
    },
    methods: {
        setLogin(e) {
            const self = this;
            var passwd = self.rec.pass;
            self.rec.password = md5(self.rec.pass);
            delete axios.defaults.headers.common["authorization"];
            axios.defaults.headers.common["authorization"] = localStorage.ctoken;
            self.dePost ("/brantas/guru/setlogin", self.rec,function(response) {
                if (response.data.success == true) {
                    self.rec.pass = "";
                    localStorage.brantastoken = response.data.token;
                    localStorage.name = response.data.user.guru_name;
                    localStorage.userid = response.data.user.guru_code;
                    localStorage.level = response.data.user.guru_level;
                    localStorage.admin = "admin";
                    localStorage.setItem('tahunajaran', JSON.stringify(response.data.thnajaran));
                    localStorage.setItem('user', JSON.stringify(response.data.user));
                    self.$toast.success('Login sukses');
                    self.$router.push('/');
                } else {
                    self.$toast.error(self.setToast('Login',response.data.msg, '','150'),{'position':'top-right'});
                    self.rec.pass = passwd;
                }
            });
                
            event.preventDefault();
            return false;            
        },
        async loadCapcha() {
            const obj = await axios({
                method: "post",
                url: "/tproject/admin/getcapcha",
                data: {},
            });
            this.cap.image = obj.data.image;
            this.cap.image_url = `<img src="${config.apiUrl}/cap/${obj.data.image}" />`;
            localStorage.ctoken = obj.data.token;
        },
        checkLogin : function(){
            const self = this;
            self.dePost('/tproject/admin/checklogin',
            {},function(response){
                if(response.data.success==true){
                    self.$router.push('/admin');
                }else{
                    self.$router.push('/login');
                }
            });
        },
    },
    mounted() {
        // this.checkLogin();
        this.loadCapcha();
        this.screenHeight = window.innerHeight;
        document.getElementById("username").focus();
    },
}
</script>
<style type="text/css" lang="css">
#panah_atas{
    margin: 35px 0 0 50px;
    width: 30px;
    /* background:#1C4672; */
    color:#fff !important; 
    background-color:#156ABF !important;
    position: relative;
}
#panah_atas:before {
    content: "";
    position: absolute;
    top: -25px; 
    left: 0;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    /* border-bottom: 25px solid #1C4672; */
    /* color:#fff !important;  */
    border-bottom: 25px solid #156ABF !important
}
</style>